import { createStore } from 'vuex';

import filter from './modules/filter.js';
import menu from './modules/menu.js';
import modals from './modules/modals/modals.js';
import shop from './modules/shop/shop.js';
import blog from './modules/blog/blog.js';
import door from './modules/products/door.js';
import glass from './modules/products/glass.js';
import configurator from './modules/products/configurator.js';
import vlog from './modules/vlog/vlog.js';
import fireplace from './modules/fireplace/fireplace.js';
import seo from './modules/seo/seo.js';
import page from './modules/page/page.js';
import search from './modules/search/search.js';
import scroll from './modules/scroll/scroll.js';
import forms from './modules/form/forms.js';
import testimonials from './modules/testimonials/testimonials.js';
import contacts from './modules/contact/contact.js';
import acoperirenationala from './modules/acoperirenationala/acoperirenationala.js';

const store = createStore({

  state() {
    return {
      name: "Sebi",
      showAskForOfferModal: false,

    };
  },
  modules: {
    filter,
    menu,
    shop,
    blog,
    door,
    glass,
    configurator,
    modals,
    vlog,
    fireplace,
    seo,
    page,
    search,
    scroll, 
    forms,
    testimonials,
    acoperirenationala, 
    contacts,
  },

});

export default store;
