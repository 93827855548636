import axios from 'axios';
// import store from '@/store/store';

export default {
    namespaced: true,
    state() {
        return {
            steps: 0,
            price: 0,
            selectedProducts: [],
            loading: true,
            allRequired: false,
            productSufix: false,
            includedProducts: [],
            addToCartError: false,
        }
    },
    getters: {
        steps(state) {
            return state.steps;
        },
        price(state) {
            return state.price;
        },
        selectedProducts(state) {
            return state.selectedProducts;
        },
        allRequired(state) {
            return state.allRequired;
        },
        loading(state) {
            return state.loading;
        },
        includedProducts(state) {
            return state.includedProducts;
        },
        addToCartError(state) {
            return state.addToCartError;
        }
    },
    mutations: {
        setPrice(state, price) {
            state.price = price;
        },
        setSteps(state, steps) {
            state.steps = steps;
        },
        unloading(state) {
            state.loading = false;
        },
        setRequired(state, status) {
            state.allRequired = status;
        },
        resetConfigurator(state) {
            state.loading = true;
            state.selectedProducts = [];
            state.steps = 0;
            state.addToCartError = false;
        },
        resetSelected(state) {
            state.selectedProducts = [];
            state.steps = [state.steps[0]];
        },
        selectStep(state, steps) {
            state.loading = true;
            var selectedSteps =  state.selectedProducts.sort((a, b) => a.step - b.step);
            var stepsToPush = [];
            selectedSteps.forEach(element => {
                if(element.step != steps.step) {
                    stepsToPush.push(element);
                }
            });
            stepsToPush.push(steps);
            state.selectedProducts =  stepsToPush.sort((a, b) => a.step - b.step);
        },
        setIncludedProducts(state, includedProducts) {
            state.includedProducts = includedProducts;
        },
        setAddToCartError(state, addToCartError) {
            state.addToCartError = addToCartError;
        },
    },
    actions: {

        resetSteps(context) {
            context.commit('resetConfigurator');
            context.dispatch('getProductSteps');
        },
        selectStep(context, data) {
            var oldStep = context.state.selectedProducts;

            // reset steps if first step is reselected with a different option
            if(oldStep.length > 1 && data.value && data.step == oldStep[0].step) {
                context.commit('resetSelected');
            } 

            context.commit('selectStep', data);
            var selectedSteps =  context.state.selectedProducts;
            var stepsState = context.state.steps;
            var requieredStepsAll = stepsState.filter( (item) => {return item.data.required_for_price == 1 ? true: false});

            if(selectedSteps.length >= requieredStepsAll.length){
                // console.log('setRequired', selectedSteps.length, requieredStepsAll.length)
                context.commit('setRequired', true);
            } else {
                context.commit('setRequired', false);
            }
            var thisData = data;
            let isSelectedStep = stepsState.find( ({ data }) => data.id == thisData.step);
            if(isSelectedStep){
                if(isSelectedStep.data.required || isSelectedStep.data.type == "text"){
                    context.dispatch('getProductSteps');
                } else {
                    context.commit('unloading');
                }

            }
        },
        getProductSteps(context) {
            let slug = window.location.pathname.replace('/produs/', '');
            // console.log('getProductSteps', slug);
            // TODO, sa preluam slug-ul curent
            var dataToSend = '';
            var selectedSteps = context.state.selectedProducts;
            selectedSteps.forEach(element => {
                // console.log('data to sent', element);
                if(typeof element.value == "string") {
                    dataToSend = dataToSend + 'step[' +  element.step + '][text]=' + element.value + '&'
                } else {
                    dataToSend = dataToSend + 'step[' +  element.step + '][]=' + element.value.id + '&'
                }
            });

            axios
                .get(`/api/v1/configurator/${slug}?${dataToSend}`)
                .then(function (response) {
                    context.commit('setSteps', response.data);
                    // check if selected products from state it is in list
                    var selectedSteps =  context.state.selectedProducts;
                    var stepsState = context.state.steps;
                    var stepsToPush = [];
                    var nameToPush = '';
                    selectedSteps.forEach(SelectedElement => {
                        let isSelectedStep = stepsState.find( ({ data }) => data.id == SelectedElement.step);
                        if(isSelectedStep) {
                            let products = isSelectedStep.products
                            let isSelectedProduct = products.find( ({ id }) => id == SelectedElement.value.id);
                            if(isSelectedProduct) {
                                stepsToPush.push(SelectedElement);
                            } else if(isSelectedStep.data.type == "text") {
                                SelectedElement.value = products[0];
                                stepsToPush.push(SelectedElement);
                            }
                            if(isSelectedStep.data.required) {
                                nameToPush += ' - ' + isSelectedProduct.name;
                                if(isSelectedStep.data.name == "Alege inaltimea totala a cosului") {
                                    nameToPush += ' ML';
                                }
                            } else if(isSelectedStep.data.type == "text" && isSelectedStep.data.name == "Alege culoarea") { 
                                let val = SelectedElement.value.name.toLowerCase();
                                let stepValue = (val.indexOf('ral') === -1 ? 'RAL ' : '') + SelectedElement.value.name;
                                nameToPush += ' - Culoare: ' + stepValue;
                            }
                        }
                    });

                    context.state.selectedProducts = stepsToPush.sort((a, b) => a.step - b.step);
                    context.state.productSufix = nameToPush;

                    var requieredStepsAll = stepsState.filter( (item) => {return item.data.required_for_price == 1 ? true: false});
                    if(selectedSteps.length < requieredStepsAll.length){
                        context.commit('setRequired', false);
                    }

                })
                .then(function(){
                    context.commit('unloading');
                })
                .catch(function (error) {
                    alert(error);
                });
        },
        getConfiguratorAllProducts(context, data) {
            var payload = new FormData;
            payload.append('steps', JSON.stringify(data));
            var config = {
                method: 'post',
                url: '/api/v1/configuratorproducts',
                data: payload
              };
            return axios(config)
            .then(function (response) {
                context.commit('setIncludedProducts', response.data);
                return response.data;
            }).catch(function(error) {
                alert(error);
            });
        },
    },

};
