
import axios from 'axios';
const getVideojs = () => import('video.js');
// import videojs from 'video.js';
import store from '@/store/store';


export default {
    namespaced: true,
    state() {
        return {
            categories: [],
            current_videos: [],
            selectedCategory: null,
            video: {},
            bestVideos: [],
            categVideos: []
        }
    },
    getters: {

        categories(state) {
            return state.categories;
        },

        currentVideos(state) {
            return state.current_videos;
        },

        video(state) {
            return state.video;
        },

        categVideos (state) {
            return state.categVideos;
        },

        bestVideos (state) {
            return state.bestVideos;
        }


    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },

        setCurrentVideos(state, current_videos) {
            state.current_videos = current_videos;
        },

        setCurrentVideo(state, currentVideo) {
            state.current_videos.forEach((element, index) => {
                if (element.categ === currentVideo.categ) {
                    state.current_videos[index].video = currentVideo.video;
                }
            });

        },

        setVideo(state, video) {
            state.video = video;
        },
        resetVideo(state) {
            state.video = [];
        },
        setCategVideos (state, videos) {
            state.categVideos = videos;
        },
        setBestVideos (state, videos) {
            state.bestVideos = videos;
        }

    },
    actions: {

        getBestVideos(context) {

            axios
                .get('api/v1/vlog/videos/mostseen')
                .then(function (response) {
                    context.commit('setBestVideos', response.data.data);
                })
                .catch(function (error) {
                    alert(error);
                });

        },

        async getCategories(context) {
            await axios
                .get('/api/v1/vlog/categories')
                .then(function (response) {
                    context.commit('setCategories', response.data.data);
                    const current_videos = []
                    response.data.data.forEach(element => {
                        const id = element.id;

                        // current_videos[id] = element.videos[0];
                        current_videos.push( {
                            categ: id,
                            video : element.videos[0]
                        });

                    });
                    context.commit('setCurrentVideos', current_videos);


                })
                .catch(function (error) {
                    alert(error);
                });
        },

        getVideoOnAPI(context, slug) {

            axios
                .get('/api/v1/vlog/video/' + slug)
                .then(function (response) {
                    context.commit('setVideo', response.data.data);
                    store.commit('seo/setParams', response.data.data.seo_params);
                    context.commit('setCategVideos', response.data.data.related);
                    return response.data.data
                })
                .catch(function (error) {
                    alert(error);
                });

        },

        // getVideo({ state }, slug) {

            // console.log(state.categories, 'categoriile curente');
            // console.log(slug, 'slug video');
        // },

        resetVideo (context) {
            context.commit('resetVideo');
        },

        loadThumbVideo(context, payload) {
            console.log(getVideojs())
            getVideojs().then(response => {
                const videojs = response.default;
                            // console.log(videojs);
            var viewportWidth = window.innerWidth || document.documentElement.clientWidth;
            if (viewportWidth > 768) {
                const video_id = 'video_' + payload.categ;
                const player = videojs.getPlayer(video_id);
                player.src({
                    type: 'video/youtube',
                   src: payload.video.video_url
                });
                player.poster('https://i.ytimg.com/vi/' + payload.video_id + '/hqdefault.jpg');
                const allPlayers = videojs.getPlayers();
                Object.entries(allPlayers).forEach(player => {
                    if (!player[1].paused()) {
                        player[1].pause();
                    }
                });
                player.play();
                context.commit('setCurrentVideo', payload);
            } else {

                // reinitializare celelalte categorii
                const categs = document.querySelectorAll(`.categ:not(.categ-${payload.categ})`);
                categs.forEach(categ => {
                    const thumbVideosCateg = categ.querySelectorAll('.thumb-video');
                    thumbVideosCateg.forEach((thumbVideo, index) => {
                        if (index === 0) {
                            thumbVideo.classList.add('first', 'active');
                        } else {
                            thumbVideo.classList.remove('active');
                        }
                    });

                });


                const activeVideo = document.querySelector(`.categ-${payload.categ} .active`);
                activeVideo.classList.remove('active', 'first');

                const thumbVideo = document.getElementById(payload.categ + '-thumb-video-' + payload.video.id);
                thumbVideo.classList.add('active');

                const wrpThumbVideo = thumbVideo.querySelector('.mobile-wrapper');


                if (videojs.getPlayer('thumb-video')) {
                    videojs.getPlayer('thumb-video').dispose();
                }

                //create element
                var videoHtmlElem = document.createElement('video');
                videoHtmlElem.classList.add('video-js', 'vjs-default-skin', 'vjs-fluid');
                videoHtmlElem.setAttribute('id','thumb-video');
                videoHtmlElem.setAttribute('controls', '');

                wrpThumbVideo.appendChild(videoHtmlElem);

                // create player
                const player = videojs('thumb-video');
                player.src({
                    type: 'video/youtube',
                    src: payload.video.video_url
                });
                player.play();


                const rect = thumbVideo.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                window.scrollTo({
                    top: scrollTop + rect.top - 120,
                    behavior: "smooth"
                });

            }
            });

        },

    },

};
