import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            testimonials: {}
        }
    },

    getters: {
        testimonials(state) {
            return state.testimonials;
        },
    },
    mutations: {
        setTestimonials(state, testimonials) {
            state.testimonials = testimonials;
        }
    },
    actions: {
        getTestimonials(context) {
            return axios
            .get(`/api/v1/testimonials`)
            .then(function (response) {
                context.commit('setTestimonials', response.data);
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            });
        }
    }

}
