export default {
    namespaced: true,
    state() {
        return {
            isNavOpen: false 
        }
    }, 
    mutations: {
        toggleNav(state) {

           state.isNavOpen = !state.isNavOpen;
      
        }, 
        setCloseNav(state) {
            state.isNavOpen = false;
        }, 
        activeScroll(state) {
            if(state.isNavOpen) {
                document.documentElement.style.overflow = 'hidden';
                return
            }

            document.documentElement.style.overflow = 'auto';
        }
    },
    actions: {
        toggleMenuNav({commit, dispatch, rootGetters}) {
            const categories = rootGetters["shop/categories"];
            if (        
                categories &&
                Object.keys(categories).length === 0 &&
                Object.getPrototypeOf(categories) === Object.prototype
                ) {
                    dispatch('shop/getCategories', false,{root:true});
                }
            commit('toggleNav');
            commit('activeScroll');
        }, 
        closeNav( {commit}) {
            commit('setCloseNav');
            commit('activeScroll');
        }

    }, 
    getters: {
        navOpen(state) {
            return state.isNavOpen;
        }
    }

};