
const state = {
        seo_params: {},
        seo_title: '',
        seo_descriptiom: '',
        seo_image: '',
        canonical_url: 'Https://www.pefoc.ro',
}

const getters = {
    seo_description(state) {
        return state.seo_description
    },
    seo_title(state) {
        return state.seo_title
    },
    seo_keywords(state) {
        return state.seo_keywords
    }, 
    seo_image(state) {
        return state.seo_image
    }, 
    canonical_url(state) {
        return state.canonical_url
    }
}
const mutations = {
    setParams(state, params) {
        // console.log('shop/setParams/mutation', params);
        state.seo_title = params.seo_title;
        state.seo_description = params.seo_description;
        state.seo_keywords = params.seo_keywords;
        state.seo_image = params.seo_image;
        state.canonical_url = params.canonical_url;
    },
}

const actions = {
    setParams(context, params) {
        context.commit('setParams', params);
    }

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
