export const generalMixin = {
    computed: {
        isDataLoad() {
            return this.$store.getters['shop/categoryIsLoad'];
        },
    },
    methods: {
        async setScrollTo() {
            await this.$store.dispatch('scroll/actScrollTo', true);
            await this.$router.push({ name: 'Home'});
        }
    }
  }