
import axios from 'axios';
import store from '@/store/store';
import router from '../../../router';
// import router from '../../../router'

export default {
    namespaced: true,
    state() {
        return {
            categories: {},
            posts: [],
            post: {},
            selectedCategory: null,
            categ: {},
            mainPageSeo: {
                seo_title: "Blog seminee: montaj, sfaturi utile, tutoriale - Pefoc.ro",
                seo_description:
                  "Afla ultimele noutati si informatii practice in materie de  montaj seminee, sobe, cosuri de fum, sfaturi utile si tutoriale pentru meseriasi. Urmareste blogul nostru de seminee.",
                seo_keywords:
                  "blog seminee, sfaturi focare, pefoc, blog pefoc, tutoriale mesteri seminee",
                seo_image: require("@/assets/images/blog-header-image.jpeg"),
              },
            blogCategIsLoad: false, 
            blogPostsIsLoad: false,
            latestPosts: [], 
        }
    },
    getters: {

        categories(state) {
            return state.categories;
        },

        posts(state) {
            // console.log(state.posts);
            return state.posts;
        },

        latestPosts(state) {
            return state.latestPosts;
        },

        post(state) {
            return state.post;
        },

        selectedCategory(state) {
            return state.selectedCategory;
        },

        categ(state) {
            return state.categ;
        }, 
        seo(state) {
            return state.mainPageSeo;
        },
        blogCategIsLoad(state) {
            return state.blogCategIsLoad;
        }, 
        blogPostsIsLoad(state) {
            return state.blogPostsIsLoad
        }

    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },

        setSelectedCategory(state, selectedCategory) {
            state.selectedCategory = selectedCategory;
        },

        setPosts(state, posts) {
            state.posts = posts;
        },
        setLatestPosts(state, posts) {
            state.latestPosts = posts;
        },

        setPost(state, post) {
            state.post = post;
        }, 

        setCateg(state, categ) {
            state.categ = categ;
        }, 
        setBlogCateg(state, isLoad) {
            state.blogCategIsLoad = isLoad;
        },
        setBlogPosts(state, isLoad) {
            state.blogPostsIsLoad = isLoad;
        }

    },
    actions: {
        getCategories(context) {
            axios
                .get('/api/v1/blog/categories')
                .then(function (response) {
                    // console.log(response);
                    context.commit('setCategories', response.data);
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        selectedCategory(context, payload) {

            context.dispatch('shop/actCategoryIsLoad', false, {root:true});

            context.commit('setSelectedCategory', payload.id);
            if (payload.slug !== '') {
                context.dispatch('getCateg', payload.slug);
            } else {
                context.commit('setCateg', {});
                context.commit('setPosts', []);
                store.commit('seo/setParams', context.state.mainPageSeo);
                context.dispatch('getPosts')
            }
        },

        async getPosts(context, currentPage) {
            return await axios
                .get('/api/v1/blog/posts', {
                    params: {
                        'category': context.getters.selectedCategory,
                        'page': currentPage,
                    },
                })
                .then(function(response) {
                    context.commit('setPosts', response.data);
                    context.commit('setBlogPosts', true);
                    return response;
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        getLatestPosts(context) {
            return axios
                .get('/api/v1/blog/posts')
                .then(function(response) {
                    // console.log(response.data);
                    context.commit('setLatestPosts', response.data.data);
                    // context.commit('setPosts', response.data);
                    // context.commit('setBlogPosts', true);
                    return response;
                })
                .catch(function (error) {
                    alert(error);
                });
            // context.dispatch('getPosts').then((response) => {
            //     // console.log(response);
            //     context.commit('setLatestPosts', response.data.data);
            // });
        }, 

        getPost(context, payload) {
            const slug = payload;
            axios
                .get('/api/v1/blog/posts/' + slug)
                .then(function(response) {
                    console.log(response);
                    if (response.data && response.data['404']) {
                        router.push({ name: "NotFound"});
                    } else {
                        context.commit('setPost', response.data);
                        const { meta_title, meta_description, og_image, canonical_url } = response.data.seostorm_options.options;
                        const seo_params = {
                            seo_title:  meta_title,
                            seo_description: meta_description,
                            seo_keywords: 'blog seminee, sfaturi focare, pefoc, blog pefoc, tutoriale mesteri seminee',
                            seo_image: og_image || response.data.header_image?.path || require('@/assets/images/blog-header-image.jpeg'),
                            canonical_url: canonical_url,
                        }
                        store.commit('seo/setParams', seo_params);
                    }
                }).catch(function(error) {
                    alert(error);
                });
        },


        getCateg(context, payload) {
            const slug = payload;
            return axios
                .get('/api/v1/blog/categories/' + slug)
                .then(function(response) {
                    // console.log(response);
                    context.commit('setSelectedCategory', response.data.id);
                    context.commit('setCateg', response.data);
                    // console.log(response);
                    store.commit('seo/setParams', response.data.seo_params);
                    return response.data;
                }).catch(function(error) {
                    alert(error);
                });
        },
        
        blogCategLoad({ commit }, isLoad) {
            commit('setBlogCateg', isLoad);
        },
        blogPostsLoad({ commit }, isLoad) {
            commit('setBlogPosts', isLoad);
        }
    },

};
