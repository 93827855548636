export default {
    namespaced: true,
    state() {
        return {
            scrollTo: false 
        }
    }, 
    getters: {
        scrollTo(state) {
            return state.scrollTo;
        }
    },
    mutations: {
        setScrollTo(state, value) {
            state.scrollTo = value;
        }
    },
    actions: {
        actScrollTo({commit}, value) {
            commit('setScrollTo', value);
        }
    }, 

};