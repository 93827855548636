import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store.js'


const routes = [
  {
    path: '/mentenanta.html',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "home" */ '../pages/Maintenance.vue'),
    meta: {
      layout: 'AppLayoutSimple'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home.vue'),
  },
  {
    path: '/produse/search/:page?',
    name: 'searchpage',
    component: () => import(/* webpackChunkName: "search" */ '../pages/search/Search.vue'),
  },
  {
    path: '/despre-noi',
    name: 'Despre Noi',
    component: () => import(/* webpackChunkName: "about" */ '../pages/about_us/AboutUs.vue')
  },
  {
    path: '/termeni-si-conditii.html',
    name: 'termeni',
    component: () => import(/* webpackChunkName: "termeni" */ '../pages/internal/InternalPage.vue'),
    meta: {
      layout: 'AppLayoutPage'
    }
  },
  {
    path: '/politica-de-confidentialitate-a-datelor.html',
    name: 'confidentialitate',
    component: () => import(/* webpackChunkName: "confidentialitate" */ '../pages/internal/InternalPage.vue'),
    meta: {
      layout: 'AppLayoutPage'
    }
  },
  {
    path: '/cum-cumpar.html',
    name: 'cumcumpar',
    component: () => import(/* webpackChunkName: "cumcumpar" */ '../pages/internal/InternalPage.vue'),
    meta: {
      layout: 'AppLayoutPage'
    }
  },
  {
    path: '/privacy-and-cookies.html',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../pages/internal/InternalPage.vue'),
    meta: {
      layout: 'AppLayoutPage'
    }
  },
  {
    path: '/conditii-de-transport.html',
    name: 'transport',
    component: () => import(/* webpackChunkName: "transport" */ '../pages/internal/InternalPage.vue'),
    meta: {
      layout: 'AppLayoutPage'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../pages/contact/Contact.vue')
  },
  {
    path: '/blog-seminee/:slug?',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../pages/blog/Blog.vue')
  },
  {
    path: '/:slug' + '.html',
    name: 'post',
    component: () => import(/* webpackChunkName: "article" */ '../pages/blog_article/BlogArticle.vue')
  },
  {
    path: '/promotie-seminee-promotii',
    name: 'Reduceri',
    component: () => import(/* webpackChunkName: "sales" */ '../pages/reduceri/Reduceri.vue')
  },
  {
    path: '/video',
    name: 'Videos',
    component: () => import(/* webpackChunkName: "video" */ '../pages/video/Video.vue')
  },
  {
    path: '/video/:slug',
    name: 'videoSingle',
    component: () => import(/* webpackChunkName: "video" */ '../pages/video/VideoSingle.vue')
  },
  {
    path: '/modele-seminee/page/all',
    name: 'Modele',
    component: () => import(/* webpackChunkName: "models" */ '../pages/modele/Modele.vue')
  },
  {
    path: '/producatori-seminee-pefoc-romania',
    name: 'parteners',
    component: () => import(/* webpackChunkName: "models" */ '../pages/parteners/Parteners.vue')
  },
  {
    path: '/vanzare-seminee-firme-executie-mesteri-sobari',
    name: 'AcoperireNationala',
    component: () => import(/* webpackChunkName: "acoperirenationala" */ '../pages/acoperirenationala/AcoperireNationala.vue'),
  },
  {
    path: '/magazin/:slug',
    name: 'County',
    component: () => import(/* webpackChunkName: "county" */ '../pages/acoperirenationala/County.vue'),
    props: true,
  },
  {
    path: '/cos-cumparaturi',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "cart" */ '../pages/cart/Cart.vue')
  },
  {
    path: '/finalizeaza-cos',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '../pages/checkout/Checkout.vue')
  },
  {
    path: '/comanda-finalizata',
    name: 'FinalOrder',
    component: () => import(/* webpackChunkName: "checkout" */ '../pages/checkout/FinalOrder.vue')
  },
  {
    path: '/produse/:slug',
    name: 'ProductList',
    component: () => import(/* webpackChunkName: "productslist" */ '../pages/product_list/ProductListCard.vue'),
    children: [
      {
        path: 'filter/:filters*',
        name: 'productsfilter',
        component: () => import(/* webpackChunkName: "productsfilter" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'sort/:sortby?',
        name: 'productssort',
        component: () => import(/* webpackChunkName: "productssort" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'page/:page?',
        name: 'productspage',
        component: () => import(/* webpackChunkName: "productspage" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'filter/:filters*/sort/:sortby?',
        name: 'productsfiltersort',
        component: () => import(/* webpackChunkName: "productsfiltersort" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'filter/:filters*/page/:page?',
        name: 'productsfilterpage',
        component: () => import(/* webpackChunkName: "productsfilterpage" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'page/:page?/sort/:sortby?',
        name: 'productssortpage',
        component: () => import(/* webpackChunkName: "productssortpage" */ '../pages/product_list/ProductListCard.vue'),
      },
      {
        path: 'filter/:filters*/page/:page?/sort/:sortby?',
        name: 'productsfiltersortpage',
        component: () => import(/* webpackChunkName: "productsfiltersortpage" */ '../pages/product_list/ProductListCard.vue'),
      }
    ]
  },

  // product
  {
    path: '/produs/:slug',
    name: 'productpage',
    component: () => import(/* webpackChunkName: "productpage" */ '../pages/product_page/ProductPage.vue'),
  },
  {
    path: '/produs-print/:slug',
    name: 'productprint',
    component: () => import(/* webpackChunkName: "product" */ '../pages/product_page/ProductPrint.vue'),
    meta: {
      layout: 'AppLayoutSimple'
    }
  },
//   {
//     path: '/htmltopdf',
//     name: 'pdfpage',
//     component: () => import(/* webpackChunkName: "pdf" */ '../pages/HtmlToPdf.vue'),
//     meta: {
//       layout: 'AppLayoutSimple'
//     }
//   },
  // {
    // path: '/about',
    // name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    // will match everything and put it under `$route.params.pathMatch`
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notfound" */ '../pages/NotFound.vue'),
    meta: {
      layout: 'AppLayoutSimple'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      // const position = {};
      // console.log(to);
      if (to.hash === '#produse1') {
        return new Promise((resolve) => {
            resolve({
              el: to.hash,
              top: 40,
              behavior: 'smooth',
            })
        });
      }
      if (to.params.filters || to.params.sort) {
        return;
      }

    }
    return { left: 0, top: 0 }
  },
});

router.beforeEach((to, from, next) => {

  // Redirect to NotFound if url contains "erp-" in the first 4 positions
  if(to.params.slug) {
    if (to.params.slug != "" && to.params.slug.substring(0, 4) == "erp-"){  // check if slug is not empty and if contains "erp-" in the first 4 positions
      router.push('/404');
    }
  }
  
  // Redirect from checkout to Cart if cartData empty
  if(to.name == 'Checkout') {
    if(store.getters["shop/cartIsLoaded"] && store.getters["shop/cartData"].length < 1) {
      router.push({ name: "Cart" })
    }
  }

  if (to.params.slug !== from.params.slug || to.params.page !== from.params.page) {
      if(store.state.menu.isNavOpen) {
        // console.log('stare nav');
        store.dispatch("menu/closeNav");
      }
  }
  next()
})


export default router;
