import axios from 'axios';
import store from '@/store/store';
// import store from '@/store/store'

export default {
    namespaced: true,
    state() {
        return {
            page: {}
        }
    },

    getters: {
        page(state) {
            return state.page;
        },
    },
    mutations: {
        setPage(state, page) {
            state.page = page;
        }
    },
    actions: {
        getPage(context, slug) {
            axios
            .get(`api/v1/pages/${slug}`)
            .then(function (response) {
                context.commit('setPage', response.data);
                store.commit('seo/setParams', response.data.seo_params);
            })
            .catch(function (error) {
                alert(error);
            });
        }
    }

}
