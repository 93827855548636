import sketchLeftImage from '@/assets/images/product/custom-door/custom-door.png';
import sketchRightImage from '@/assets/images/product/custom-door/custom-door-side-right.png';
import sketchOuterLeftImage from '@/assets/images/product/custom-door/custom-door-side-left-type-out.png';
import sketchOuterRightImage from '@/assets/images/product/custom-door/custom-door-side-right-type-out.png';
import threeDLeftImage from '@/assets/images/product/custom-door/custom-door-3d.png';
import threeDRightImage from '@/assets/images/product/custom-door/custom-door-side-right-3d.png';
import threeDOuterLeftImage from '@/assets/images/product/custom-door/custom-door-side-left-type-out-3d.png';
import threeDOuterRightImage from '@/assets/images/product/custom-door/custom-door-side-right-type-out-3d.png';

export default {
    namespaced: true,
    state() {
        return {
            doorWidthDim: 0,
            doorHeightDim: 0,
            doorPosition: 'left',
            doorType: 'inner',
            isPositionSelected: false,
            isTypeSelected: false,
            images: {
                sketchLeftImage,
                sketchRightImage,
                sketchOuterLeftImage,
                sketchOuterRightImage,
                threeDLeftImage,
                threeDRightImage,
                threeDOuterLeftImage,
                threeDOuterRightImage,
                sketchImage: sketchLeftImage,
                threeDImage: threeDLeftImage,
            },
            price: 0
        }
    },
    getters: {
        doorWidthDim(state) {
            return state.doorWidthDim;
        },

        doorHeightDim(state) {
            return state.doorHeightDim;
        },

        doorPosition(state) {
            return state.doorPosition;
        },

        doorType(state) {
            return state.doorType;
        },

        isPositionSelected(state) {
            return state.isPositionSelected;
        },

        isTypeSelected(state) {
            return state.isTypeSelected;
        },

        images(state) {
            return state.images;
        },
        doorPrice(state) {
            return state.price;
        },
    },
    mutations: {
        setDoorWidthDim(state, doorWidthDim) {
            state.doorWidthDim = doorWidthDim;
        },

        setdoorHeightDim(state, doorHeightDim) {
            state.doorHeightDim = doorHeightDim;
        },

        setdoorPosition(state, doorPosition) {
            state.doorPosition = doorPosition;
        },

        setDoorType(state, doorType) {
            state.doorType = doorType;
        },

        setIsPositionSelected(state, isPositionSelected) {
            state.isPositionSelected = isPositionSelected;
        },

        setIsTypeSelected(state, isTypeSelected) {
            state.isTypeSelected = isTypeSelected;
        },

        setImages(state, images) {
            state.images.sketchImage = images.sketchImage;
            state.images.threeDImage = images.threeDImage;
        },
        setPrice(state, price) {
            state.price = price;
        }

    },
    actions: {
        actionDoorWidthDim(context, data) {
            context.commit('setDoorWidthDim', data);
        },

        actionDoorHeightDim(context, data) {
            context.commit('setdoorHeightDim', data);
        },

        actionDoorPosition(context, data) {
            context.commit('setdoorPosition', data);
        },

        actionDoorType(context, data) {
            context.commit('setDoorType', data);
        },

        actionIsPositionSelected(context, data) {
            context.commit('setIsPositionSelected', data);
        },

        actionIsTypeSelected(context, data) {
            context.commit('setIsTypeSelected', data);
        },

        actionImages(context, data) {
            context.commit('setImages', data);
        },

        setPrice(context, data) {
            context.commit('setPrice', data);
        },
    },

};
