import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            contacts: {},
            HolidayImage: {}
        }
    },

    getters: {
        contacts(state) {
            return state.contacts;
        },
        HolidayImage(state) {
            return state.HolidayImage;
        },
    },
    mutations: {
        setContacts(state, contacts) {
            state.contacts = contacts;
        },
        setHolidayImage(state, HolidayImage) {
            state.HolidayImage = HolidayImage;
        }
    },
    actions: {
        getContacts(context) {
            return axios
            .get(`/api/v1/contacts`)
            .then(function (response) {
                context.commit('setContacts', response.data);
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            });
        },
        getHolidayImage(context) {
            return axios
            .get(`/api/v1/HolidayImage`)
            .then(function (response) {
                context.commit('setHolidayImage', response.data);
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            });
        }
    }
}