import axios from "axios";
import { useGtm } from '@gtm-support/vue-gtm';

export default {
    namespaced: true,
    state() {
        return {
            searchProducts: [],
            total: 0,
            showSearchNav: false,
            dataLoaded: false,
            inputValue: '',
            last_page: 1,
            page: 1,
        }
    },
    getters: {
        products(state) {
            return state.searchProducts;
        },
        total(state) {
            // console.log('get pagination', state.total);
            return state.total;
        },
        searchNavbar(state) {
            return state.showSearchNav;
        },
        isDataLoaded(state) {
            return state.dataLoaded;
        },
        inputValue(state) {
            return state.inputValue;
        },
        lastPage(state) {
            return state.last_page;
        },
        page(state) {
            return state.page;
        },
    },
    mutations: {
        setSearchProducts(state, results) {
            state.searchProducts = results;
        },
        setPagination(state, meta) {
            // console.log(state);
            // console.log(meta);
            state.total = meta.total;
            // console.log('set pagination', state.total);
            state.last_page = meta.last_page;
        },
        setSearchNavbar(state, isOpen) {
            state.showSearchNav = isOpen;
        },
        setDataLoaded(state, isLoaded) {
            state.dataLoaded = isLoaded;
        },
        setSearchInput(state, value) {
            state.inputValue = value;
        },
        setPage(state, page) {
            state.page = page;
        },
        setTotal(state, value) {
            state.total = value;
            // console.log(state.total);
        }
    },
    actions: {
        async search({ commit, dispatch }, params) {
            const gtm = useGtm();
            dispatch('shop/actionSpinner', true,{root:true});
            // console.log(params);
            // console.log('payload 1', params);
            if (params.page === 'all') {
                params.page = 1;
            }
            // console.log('payload 2', params);
            gtm.trackEvent({
                event: 'Search',
                category : 'Search',
                label : params.search
              })
            await axios
            .get('/api/v1/search', { params })
            .then(function (response) {
                // console.log(response);
                commit('setSearchProducts', response.data.data);
                commit('setPagination', response.data.meta);
                // console.log('Data Loaded', state.dataLoaded);

            }).then(() => {
                commit('setDataLoaded', true);
                dispatch('shop/actionSpinner', false,{root:true});

            })
            .catch(function (error) {
                dispatch('shop/actionSpinner', false,{root:true});
                alert('A aparut o eroare: ' + error);
            });
        },
        stateNavbar({ commit}, isOpen) {
            commit('setSearchNavbar', isOpen);
        },
        resetSearchProducts({ commit }) {
            commit('setSearchProducts', []);
            commit('setDataLoaded', false);
            // commit('setTotal', 0);
        },
        syncSearchInput({ commit }, value) {
            commit('setSearchInput', value);
        },
        actPage({commit}, page) {
            commit('setPage', page);
        },
        changeDataLoaded({ commit }, isLoaded) {
            commit('setDataLoaded', isLoaded);
        }
    }
};
