
export default {
    namespaced: true,
    state() {
        return {
            glassWidthDim: 0,
            glassHeightDim: 0,
            price: 0,
        }
    },
    getters: {
        glassWidthDim(state) {
            return state.glassWidthDim;
        },

        glassHeightDim(state) {
            return state.glassHeightDim;
        },
        glassPrice(state) {
            return state.price;
        },

    },
    mutations: {
        setGlassWidthDim(state, glassWidthDim) {
            state.glassWidthDim = glassWidthDim;
        },

        setGlassHeightDim(state, glassHeightDim) {
            state.glassHeightDim = glassHeightDim;
        },

        setPrice(state, price) {
            state.price = price;
        },

    },
    actions: {
        actionGlassWidthDim(context, data) {
            context.commit('setGlassWidthDim', data);
        },

        actionGlassHeightDim(context, data) {
            context.commit('setGlassHeightDim', data);
        },

        setPrice(context, data) {
            context.commit('setPrice', data);
        },
    },

};
