import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            countiesInfo: {}
        }
    },

    getters: {
        countiesinfo(state) {
            return state.countiesinfo;
        },
    },
    mutations: {
        setCountiesInfo(state, countiesinfo) {
            state.countiesinfo = countiesinfo;
        }
    },
    actions: {
        getCountiesInfo(context, countySlug="") {
            return axios
            .get(`/api/v1/countiesinfo/${countySlug}`)
            .then(function (response) {
                context.commit('setCountiesInfo', response.data);
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            });
        },
        getCountiesCategories(context, countyId="") {
            return axios
            .get(`/api/v1/getcountycategories/${countyId}`)
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            })
        }
    }

}
