export default {
    namespaced: true,
    state() {
        return {
            showAskForOfferModal: false,
            showAddToCartModal: false,
        }
    }, 
    mutations: {
        setAskForModalState(state, isOpen) {

            state.showAskForOfferModal = isOpen;
      
        }, 
        setAddToCartModal(state, isOpen) {
            state.showAddToCartModal = isOpen;
        }
    },
    actions: {
        askForOfferState(context, isOpen) {  
            context.commit('setAskForModalState', isOpen);
        },
        addToCartModal(context, isOpen) {
            context.commit('setAddToCartModal', isOpen);
        }

    }, 
    getters: {
        modalAskForOfferState(state) {
            return state.showAskForOfferModal;
        },
        showAddToCartModal(state) {
            return state.showAddToCartModal;
        }
    }

};