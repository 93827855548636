import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            contactMessage: 'Mesajul se trimite...',
            showResponseContact: false,
            showInvalidCUI: false, 
            invalidCuiMessage: ''            
        }
    }, 
    getters: {
        contactMessage(state) {
            return state.contactMessage;
        },
        modalResponseContact(state) {
            return state.showResponseContact
        },
        showInvalidCui(state) {
            return state.showInvalidCUI;
        }, 
        invalidCuiMessage (state) {
            return state.invalidCuiMessage
        }
    },
    mutations: {
        setContactMessage(state, contactMessage) {
            state.contactMessage = contactMessage;
        },        
        setResponseContact(state, isOpen) {
            state.showResponseContact = isOpen;
        },
        setInvalidCui(state, isOpen) {
            state.showInvalidCUI = isOpen;
        }, 
        setInvalidCUIMessage(state, message) {
            state.invalidCuiMessage = message;
        }
    },
    actions: {
        sendContactForm({commit}, data) {

            // console.log(data);
            var config = {
                method: 'post',
                url: '/api/v1/contact-form',
                data : data
              };
            //   console.log(context);
           return axios(config)
            .then(function (response) {
                commit('setContactMessage', "Mesajul dumneavoastra a fost receptionat cu succes!");
                // console.log(response);
                return response.data;
            })
            .catch(function (error) {
                commit('setContactMessage', "A aparut o eroare! Mai incercati inca o data!");
                console.log('error', error);
            });

        },
        setContactMessage({commit}, msg) {
            commit('setContactMessage', msg);
        }, 
        responseContact(context, isOpen) {
            context.commit('setResponseContact', isOpen);
            if (!isOpen) {
                context.commit('setContactMessage', 'Mesajul se trimite...');
            }
        },
        changeInvalidCUI({ commit }, isOpen) {
            commit('setInvalidCui', isOpen);
        }, 
        invalidCuiMessage({ commit }, message) {
            commit ('setInvalidCUIMessage', message);
        }

    }
};