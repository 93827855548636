import axios from 'axios';

export default {
    namespaced: true,
    state() {
        return {
            fireplaces: [],
            filteredFireplace: [],
            specification: {
                shape: '',
                style: ''
            },
            specs: [
                {
                    name: 'shape',
                    value: ''
                },
                {
                    name: 'style',
                    value: ''
                }
            ],
            countFilteredFireplace: 0,
            isFiltered: false,
        }
    },
    getters: {

        fireplaces(state) {
            return state.fireplaces;
        },

        filteredFireplace(state) {
            return state.filteredFireplace;
        },

        countFilteredFireplace(state) {
            // console.log(state.countFilteredFireplace);
            return state.countFilteredFireplace;
        }, 
        isFiltered(state) {
            return state.isFiltered; 
        }

    },
    mutations: {

        setFireplaces(state, fireplaces) {
            state.fireplaces = fireplaces;
        },
        setFilteredBySearch(state, filteredBySearch) {
            state.filteredFireplace = filteredBySearch;
            // console.log(state.filteredFireplace);
        },

        setSpecification(state, specs) {

            state.specs = specs

        },

        setCountFilteredFireplace(state, count) {
            state.countFilteredFireplace = count;
        }, 
        setFilteredState(state, isFiltered) {
            state.isFiltered = isFiltered;
        }

    },
    actions: {

        getFireplaces(context) {
            axios
                .get('/api/v1/fireplaces')
                .then(response => {
                    context.commit('setFireplaces', response.data.data);
                    context.commit('setFilteredBySearch', response.data.data);
                })
                .catch(err => {
                    console.log(err, 'err');
                });
        },

        getFireplacesBySearch(context, firplaceNumber) {

            const filteredArray = context.state.filteredFireplace.filter(fireplace => {

                const currentNo = +fireplace.title.split('Model Foc')[1];
                return firplaceNumber <= currentNo;

            });

            context.commit('setFilteredBySearch', filteredArray);

        },

        filterBySpec(context) {
            context.commit('setFilteredState', true);

            let filteredArray = context.state.fireplaces.filter(fireplace => {

                let matchfireplace = true;
                context.state.specs.forEach(spec => {
                    if (spec.value != '' && spec.value !== fireplace[spec.name]) {
                        matchfireplace = false;
                    }
                });

                return matchfireplace;
            });

            // console.log(filteredArray);

            context.commit('setCountFilteredFireplace', filteredArray.length);

            context.commit('setFilteredBySearch', filteredArray);



        },

        specification (context, payload) {
            
            const newSpecs = context.state.specs.map(spec => {
                if (spec.name === payload.spec) {
                    spec.value = payload.value;
                }
                return spec;
            })
            context.commit('setSpecification', newSpecs);
            
        },

        resetFilter(context) {
            context.commit('setFilteredBySearch', context.state.fireplaces);
        }, 
        changeFilteredState(context, isFiltered) {
            context.commit('setFilteredState', isFiltered);
        }

    },

};
