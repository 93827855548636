const state = {
    imgFilter: true,
    modeleFilter: false,
};

// getters
const getters = {
    imgFilter: state => {
        return state.imgFilter;
    },

    modeleFilter: state => {
        return state.modeleFilter;
    },
};

// actions
const actions = {
    actionImgFilter(context, flag) {
        context.commit('setImgFilter', flag);
    }

};

// mutations
const mutations = {
    HideimgFilter(state) {
        // console.log('merge');
        return state.imgFilter = false;
    },
    setImgFilter(state, flag) {
        return state.imgFilter = flag;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}