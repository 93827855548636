
import axios from 'axios';
import store from '@/store/store';
import router from '../../../router';

export default {
    namespaced: true,
    state() {
        return {
            categories: {},
            loadCategs: false,
            product: {},
            products: [],
            category: {},
            parentCategSlug: 0,
            categoryIsLoad: false,
            // per_page: 28,
            page: 1,
            total: '',
            cartProducts: [],
            cartData: [],
            cartIsLoaded: false,
            filters: [],
            filter_params: {},
            property_params: [],
            promotions: {},
            order: {},
            spinner: false,
            pageAll: false,
            currency: {},
            orderMessage: "",
            lastToCart: "",
            quantity: 1,
            text_preview: "",
            addtocartnavbar: false,
            bigMenu: {},
            selFromShop: false,
            companyData: {},
            judeteData: {},
            paymentMethods: [],
            finalOrderCart: [],
        }
    },
    getters: {

        category(state) {
            return state.category;
        },
        topParentSlug(state) {
            return state.parentCategSlug;
        },
        categoryIsLoad(state) {
            // console.log(state.categoryIsLoad);
            return state.categoryIsLoad;
        },
        categories(state) {
            return state.categories;
        },
        loadCategs(state) {
            return state.loadCategs;
        },
        product(state) {
            return state.product;
        },
        products(state) {
            // console.log(state.products, 'vrea');
            return state.products;
        },
        totalProducts(state) {
            return state.total;
        },
        cartProducts(state) {
            // console.log(state.cartProducts, 'CartProducts');
            return state.cartProducts;
        },
        cartData(state) {
            // console.log(state.cartData, 'cartData');
            return state.cartData;
        },
        cartIsLoaded(state) {
            return state.cartIsLoaded;
        },
        filters(state) {
            return state.filters;
        },
        filter_params(state) {
            return state.filter_params;
        },
        propertyParams(state) {
            return state.property_params;
        },
        promotions(state) {
            return state.promotions;
        },
        order(state) {
            return state.order;
        },
        spinner(state) {
            return state.spinner;
        },
        page(state) {
            return state.page;
        },
        pageAll(state) {
            return state.pageAll;
        },
        currency(state) {
            return state.currency
        },
        orderMessage(state) {
            return state.orderMessage
        },
        lastToCart(state) {
            return state.lastToCart
        },
        quantity(state) {
            return +state.quantity;
        },
        text_preview(state) {
            return state.text_preview;
        },
        addToCartNavbar(state) {
            return state.addtocartnavbar;
        },
        bigMenu(state) {
            return state.bigMenu;
        },
        selFromShop(state) {
            return state.selFromShop;
        },
        companyData(state) {
            return state.companyData;
        },
        judeteData(state) {
            return state.judeteData;
        },
        paymentMethods(state) {
            return state.paymentMethods;
        },
        finalOrderCart(state) {
            return state.finalOrderCart;
        },
    },
    mutations: {
        setCateg(state, category) {
            state.parentCategSlug = category.breadcrumbs[0].slug;
            state.category = category;

        },
        setCategDescription(state, description) {
            state.category.preview_text = description;
        },
        setCategoryIsLoad(state, isLoad) {
            state.categoryIsLoad = isLoad;
            // console.log(state.categoryIsLoad);
        },
        setCategories(state, categories) {
            // const deep1 = categories.map((categ) => {
            //     let hasChildren = false;
            //     if (categ.children.length) {
            //         hasChildren = true;
            //     }
            //     let newCateg = {
            //       name: categ.name,
            //       slug: categ.slug,
            //       children: [],
            //       hasChildren: hasChildren,
            //     };
            //     return newCateg;
            //   });
            //   console.log(deep1);
            state.categories = categories;
        },
        setLoadCategs(state, loadCategs) {
            state.loadCategs = loadCategs;
        },
        setProduct(state, product) {
            state.parentCategSlug = product.category.breadcrumbs[0].slug;
            state.product = product;
        },
        setProducts(state, products) {
            // Cand da back rapid prin mai multe categorii e posibil sa se faca mai multe requesturi de get products de pe alte categorii, si daca requesturile vechi se finalizeaza ultimele o sa populeze state-ul cu date gresite si atunci am verificat daca categoria din request e aceeasi cu cea din state/curenta 
            if(state.category.slug === products.config.params.category) {
                state.products = products.data;
            }
        },
        setPagination(state, meta) {
            // console.log(meta);
            state.total = meta.total;
        },
        resetProducts(state) {
            state.products = [];
            state.property_params = [];
            state.category = {};
            state.categoryIsLoad = false;
            state.filters = [];
            state.filter_params = {};
        },
        resetOnlyProducts(state) {
            state.products= [];
        },
        resetProduct(state) {
            state.product = {}
            state.addtocartnavbar = false;
            state.categoryIsLoad = false;
        },
        syncCartData(state, data) {
            state.cartData = data;
            state.quantity = 1;
            // console.log(data, 'syncCartData');
        },
        setCartIsLoaded(state, value) {
            state.cartIsLoaded = value;
        },
        syncCartProducts(state, products) {
            state.cartProducts = products;
            // console.log(products, 'syncCartProducts');
        },
        setFilters(state, filters) {
            state.filters = filters.map(el => {
                el.values.map(value => {
                    value.isChecked = false;
                    return value;
                });
                return el;
            });
        },
        setFilterParam(state, param) {
            state.filter_params[param.key] = param.value;
        },
        setPropertyParam(state, propertyParam) {
            state.property_params.push(propertyParam);
        },
        incrementItemQuantity(state) {
            const cartItem = state.products.find(
                item => console.log(item, 'dada')
                )
                console.log(cartItem, 'nunu');
        },
        // removeFromCart(state, item) {
        // //     // const index = state.cart.findIndex(product => product.id == id)
        // //     // console.log(this.state.cart, 'oioio');
        // //     // state.cart.splice(index, 1)
        // //     state.cart = state.cart.filter(item => {
        // //         return item.product.id !== id;
        // //     })
        // // let index = state.cart.indexOf(item);
        // // state.cart.splice(index, 1);
        // }
        setPromotions(state, promotions) {
            state.promotions = promotions;
        },
        setOrderData(state, order) {
            state.order.data = order;
        },
        setOrderFormdata(state, formDAta) {
            state.order.formData = formDAta;
        },
        setSpinner(state, data) {
            state.spinner = data;
        },
        setPage(state, page) {
            state.filter_params.page = page;
            state.page = page;
        },
        setPageAll(state, isPageAll) {
            state.pageAll = isPageAll;
        },
        setCurrency(state, currency) {
            state.currency = currency;
        },
        setOrderMessage(state, orderMessage) {
            state.orderMessage = orderMessage;
        },
        lastToCart(state, params) {
            var cart = state.cartData
            let position = cart.positions;
            if('property' in params) {
                position.forEach(element => {
                    if(element.property['custom-price'] == params.property['custom-price']){
                        state.lastToCart = element;
                    }
                });
            } else {
                var isSelected = cart.positions.find(
                    ({ offer }) => offer.id == params.offer_id
                );
                state.lastToCart = isSelected;
            }
        },
        resetFastOrder(state) {
            state.orderMessage = "";
        },
        setQty(state, qty) {
            state.quantity = qty;
            // console.log(state.quantity);
        },
        setAddToCartNavbar(state, isVisible) {
            state.addtocartnavbar = isVisible;
        },
        setBigMenu(state, bigMenu) {
            state.bigMenu = bigMenu;
        },
        setSelFromShop(state, isFromShop) {
            state.selFromShop = isFromShop;
        },
        setCompanyData(state, companyData) {
            state.companyData = companyData;
        },
        setJudeteData(state, judeteData) {
            state.judeteData = judeteData;
        },
        setPaymentMethods(state, paymentMethods) {
            state.paymentMethods = paymentMethods;
        },
        setFinalOrderCart(state, finalOrderCart) {
            state.finalOrderCart = finalOrderCart;
        },
    },
    actions: {
        getBigMenu(context) {
            axios
                .get('/api/v1/big-menu')
                .then(function (response) {
                    context.commit('setBigMenu', response.data.data);
                })
                .catch(function (error) {
                    alert(error);
                });
        },
        getCategories(context) {
            context.dispatch('actLoadCategs', true);
            axios
                .get('/api/v1/menu')
                .then(function (response) {
                    // console.log(response);
                    context.commit('setCategories', response.data.data);
                    context.dispatch('actLoadCategs', false);
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        // getMenuCategories(context) {
        //     context.dispatch('actLoadCategs', true);
        //     axios
        //         .get('/api/v1/menu')
        //         .then(function (response) {
        //             // console.log(response);
        //             context.commit('setCategories', response.data.data);
        //             context.dispatch('actLoadCategs', false);
        //         })
        //         .catch(function (error) {
        //             alert(error);
        //         });
        // },

        getSubcategs() {
            const subcategs = [
                {
                  name: "Focare Seminee",
                  slug: "focare-seminee_2",
                  children: [],
                  hasChildren: false,
                },
                {
                  name: "Termoseminee",
                  slug: "termoseminee-seminee-centrala_3",
                  children: [],
                  hasChildren: false,
                },
                {
                  name: "Sobe pe lemne",
                  slug: "soba-semineu-pe-lemne-fonta-tip-centrala_18",
                  children: [],
                  hasChildren: false,
                },
                {
                  name: "Seminee premium",
                  slug: "seminee-premium-lux-cele-mai_310",
                  children: [],
                  hasChildren: true,
                },
                {
                  name: "Seminee modulare prefabricate",
                  slug: "semineu-modular-prefabricat-kit-finisaj-lemne_326",
                  children: [],
                  hasChildren: false,
                },
              ];
              return subcategs;
            // context.commit('setSubcategs', subcategs);
        },

        actLoadCategs({commit}, isLoad) {
            commit('setLoadCategs', isLoad);
        },

        async getCateg(context, slug) {
            return await axios
                .get(`/api/v1/categories/${slug}`)
                .then(function (response) {
                    context.commit('setCateg', response.data.data);
                    // console.log('test_seo', response.data);
                    store.commit('seo/setParams', response.data.data.seo_params);
                    return response.data.data;
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        actCategoryIsLoad(context, isLoad) {
            context.commit('setCategoryIsLoad', isLoad);
        },

        // getProduct(context, product_id) {
        //     console.log(product_id);
        //     axios
        //         .get(`api/v1/products/${product_id}`)
        //         .then(function (response) {
        //             console.log(response);
        //             context.commit('setProduct', response.data);
        //         })
        //         .catch(function (error) {
        //             alert(error);
        //         });
        // },

        async getProduct(context, slug) {
            return await axios
                .get(`/api/v1/products/${slug}`)
                .then(function (response) {
                    if(response.data.data.category.slug.substring(0, 4) == "erp-" ) {
                        router.push('/404');
                    } else { 
                        const product = response.data.data;
                        if (product.has_related_parent && !product.active) {
                            window.location.href = '/produs/' + product.related_parent_slug;
                        } else {
                            context.commit('setProduct', response.data.data);
                            store.commit('seo/setParams', response.data.data.seo_params);
                            return response.data
                        }
                    }                    
                })
                .catch(function (error) {
                    console.log(error);
                    // router.push({ name: 'NotFound'});
                });
        },

        async getProducts({commit, state}, params) {

            // console.log(state.category);
            // debugger;
            state.spinner = true;

            // console.log('2', params);

            // setare descriere in functie de un filtru singular selectat
            if (state.property_params.length === 1 && state.property_params[0].option.seo.filter_description) {
                let category = state.category.seo_params.seo_title ? state.category.seo_params.seo_title : state.category.name;
                let filterName = state.property_params[0].filter.name.toLowerCase();
                let filterValue = state.property_params[0].option.value;
                state.text_preview = state.property_params[0].option.seo.filter_description;
                let seo_params = {
                    seo_title: state.property_params[0].option.seo.meta_title ? state.property_params[0].option.seo.meta_title :  category + " " + filterName + " " + filterValue,
                    seo_description: state.property_params[0].option.seo.meta_description
                }
                store.commit('seo/setParams', seo_params);
            } else {
                state.text_preview = state.category.preview_text;
                store.commit('seo/setParams', state.category.seo_params);
            }



            if (params.page === 'all') {
                params.page = 1;
            } else {
                params.per_page = undefined;
            }

            // this code was used when were
            // a single product on a filter page
            // to not redirect towords product page
            // let hasProperty = false;
            // for (const param in params) {
            //     if (param.includes('property')) {
            //         hasProperty = true;
            //     }
            // }



            return await axios
                .get('/api/v1/products', { params })
                .then(function (response) {
                    // console.log(state.category);
                    // if(response.data.data.length > 0) {
                    //     let products = response.data.data
                    //     var curentCategory = products.find(({ category }) => category.slug == params.category).category;
                    //     store.commit('seo/setParams', curentCategory.seo_params);
                    //     commit('setCateg', curentCategory);
                    // }

                    // this code was used for redirect if a product was single on that page (exclude: filter page(hasProperty), other then first page (params.page))
                    // if (response.data.data.length === 1 && !hasProperty && params.page === 1) {
                    //     router.push({ name: 'productpage', params: { slug: response.data.data[0].slug } });
                    // }

                    // debugger;
                    commit('setProducts', response);
                    commit('setPagination', response.data.meta);
                    // commit('setPage', params.page);
                    state.spinner = false;
                    //console.log(response.data);
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                    // router.push({ name: 'NotFound'});
                });
        },

        resetProductsArray(context) {
            context.commit('resetProducts');
            context.dispatch("filter/actionImgFilter", false,  {root:true});
        },
        resetOnlyProductsArray(context) {
            context.commit('resetOnlyProducts');
        },

        resetProductObj(context) {
            context.commit('resetProduct');
        },

        async getFilters(context, slug) {
            await axios
                .get(`/api/v1/products/${slug}/filter-list`)
                .then(function (response) {
                    // console.log(categ_id, 'categ_id');
                    // console.log(response);
                    context.commit('setFilters', response.data);
                })
                .catch(function (error) {
                    alert(error);
                });

        },

        addPropertyParam(context, param) {
            const propertyObj = {
                id: param.id,
                key: param.key,
                optionId: param.optionId,
                value: param.value,
                measure: param.filter.measure,
                filter: param.filter,
                option: param.option
            };

            // console.log(param);

            // context.state.filters = context.state.filters.map(el => {
            //     el.values.map(value => {
            //         value.isChecked = false;
            //         return value;
            //     });
            //     return el;
            // });

            context.state.filters = context.state.filters.map(element => {

                if (element.id === param.filter.id) {
                    element.values.map(value => {

                        if(value.id === param.optionId) {
                            value.isChecked = true;
                        }
                        return value;
                    });
                }

                return element;
            });

            // console.log(context.state.property_params);

            context.commit('setPropertyParam', propertyObj)
            context.dispatch('addFilterParam', param);
        },

        removePropertyParam(context, param) {
            context.state.property_params = context.state.property_params.filter((activeFilter) => {
                return activeFilter.id != param.id;
            })


            context.state.filters = context.state.filters.map(element => {
                if (element.id === param.filter.id) {
                    element.values.map(value => {
                        if(value.id === param.optionId) {
                            value.isChecked = false;
                        }
                        return value;
                    });
                }
                return element;
            });

            context.dispatch('removeFilterParam', param);
        },

        addFilterParam(context, param) {
            context.commit('setFilterParam', param);
        },

        removeFilterParam(context, param) {
            // console.log(param);
            delete context.state.filter_params[param.key];
            // console.log('from remove');
            // console.log(context.state.filter_params);
            // if (context.state.page === 1)
            context.dispatch('getProducts', context.state.filter_params);
        },

        resetAllFilters({state, dispatch}) {

            state.property_params.filter(element => {
                delete state.filter_params[element.key];
                return element;
            })
            state.property_params = [];
            // console.log(state.category);
            dispatch('getFilters', state.category.slug);
            dispatch('getProducts', state.filter_params);

        },

        addToCart({commit}, params) {
            var message;
            var data = new FormData();
            for (const [key, element] of Object.entries(params)) {
                if(key == 'property') {
                    for (const [pkey, pelement] of Object.entries(element)) {
                        data.append('cart[0][property][' + pkey + ']', pelement);
                    }
                } else {
                    data.append('cart[0][' + key + ']', element);
                }
            }
            var config = {
                method: 'post',
                url: '/api/v1/cart/add',
                data : data
              };

            axios(config)
            .then(function (response) {
                message = response.data.message;
                commit('syncCartData', response.data.data);
                commit('lastToCart', params);
            })
            .catch(function (error) {
                console.log(error);
            });
            return message;

        },

        updateCart({commit}, params) {

            // console.log(params);
            var data = new FormData();

            data.append('cart[0][offer_id]', params.offer_id);
            data.append('cart[0][quantity]', params.quantity);
            data.append('cart[0][id]', params.id);

            // console.log(data);

            var config = {
                method: 'post',
                url: '/api/v1/cart/update',
                data : data
            };
            axios(config)
            .then(function (response) {
                // console.log(response);
                // message = response.data.message;
                commit('syncCartData', response.data.data);

                // dispatch('getCart');
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        removeProductFromCart({commit}, payload) {
            let data = new FormData();
            data.append('cart[0]', payload.position_id);
            data.append('type', 'position');
            const config = {
                method: 'post',
                url: '/api/v1/cart/remove',
                data: data
            };

            return axios(config)
            .then(function(response) {
                commit('syncCartData', response.data.data);
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        getCart(context, paymentMethodId) {

            var config = {
                method: 'get',
                url: '/api/v1/cart/data/' + paymentMethodId,
              };

            return axios(config)
            .then(function (response) {
                context.commit('syncCartData', response.data.data);
                return response.data.data;
            })
            .catch(function (error) {
                console.log(error);
            });
        },

        actCartIsLoaded({commit}, value) {
            commit('setCartIsLoaded', value);
        },

        getPromotions(context, params) {

            if(!params) {
                params = {
                    limit: 20,
                    start: 0,
                }
            }

            var url = '/api/v1/products/promotions/' + params.limit + '/' + params.start;
            if(params?.category_id) {
                url += '/' + params.category_id;
            }

            return axios
            .get(url)
            .then(function (response) {
                context.commit('setPromotions', response.data);
                context.dispatch('actCategoryIsLoad', true);
                return response.data;
            })
            .catch(function (error) {
                alert(error);
            });
        },

        createOrder({commit, state}, data) {
            var cartData = state.cartData;
            var message;
            var config = {
                method: 'post',
                url: '/api/v1/orders/create',
                data : data
              };
            axios(config)
            .then(function (response) {
                message = response.data.message;
                commit('setOrderData', response.data.data);
                commit('setOrderFormdata', data);
                if (response.data.data) {
                    commit('setFinalOrderCart', cartData);
                    state.cartData = [];
                    router.push({ name: 'FinalOrder' });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
            return message;

        },

        fastOrder({ commit}, data) {

            // console.log(state);
            var message;
            var config = {
                method: 'post',
                url: '/api/v1/orders/fast-order',
                data : data
              };

            axios(config)
            .then(function (response) {
                // console.log(response);
                message = response.data;
                commit('setOrderMessage', message);
            })
            .catch(function (error) {
                console.log(error);
            });
            return message;

        },

        resetFastOrder({commit}) {
            commit('resetFastOrder');
        },

        actionSpinner({commit}, data) {
            commit('setSpinner', data);
        },
        actPage({commit}, page) {
            commit('setPage', page);
        },

        actPageAll({commit}, isPageAll) {
            commit('setPageAll', isPageAll);
        },
        getCurrency(context) {
            return axios
                .get('/api/v1/curs-valutar/')
                .then(function(response) {
                    // console.log(response);
                    context.commit('setCurrency', response.data);
                    return response.data;
                }).catch(function(error) {
                    alert(error);
                });
        },

        changeQty(context, qty) {
            context.commit('setQty', qty);
        },


        actAddToCartNavbar({ commit }, isVisible) {
            commit('setAddToCartNavbar', isVisible);
        },

        changeSelFromShop({ commit }, isFromShop) {
            commit('setSelFromShop', isFromShop);
        },
        getCompanyData(context, companyId) {
            return axios
                .get(`/api/v1/company-data/${companyId}`)
                .then(function(response) {
                    // console.log(response);
                    context.commit('setCompanyData', response.data);
                    return response.data;
                }).catch(function(error) {
                    alert(error);
                });
        },
        getLocationData(context, countyId=0) {
            return axios
                .get(`/api/v1/locations/${countyId}`)
                .then(function(response) {
                    if(countyId == 0) {
                        context.commit('setJudeteData', response.data);
                    }
                    return response.data;
                }).catch(function(error) {
                    alert(error);
                });
        },
        getPaymentMethods(context) {
            return axios
                .get('/api/v1/cart/payment_method_list')
                .then(function(response) {
                    context.commit('setPaymentMethods', response.data.data);
                    return response.data;
                }).catch(function(error) {
                    alert(error);
                });
        },
        getPostalCode(context, data) {
            var payload = new FormData();
            payload.append('state', data.state);
            payload.append('city_name', data.city_name);
            payload.append('street', data.street);
            payload.append('number', data.number);

            var config = {
                method: 'post',
                url: '/api/v1/postalcode',
                data: payload
              };
            return axios(config)
            .then(function (response) {
                return response.data;
            }).catch(function(error) {
                alert(error);
            });
        },
        getRelatedProducts(context, slug) {
            return axios
                .get(`/api/v1/products/${slug}/relatedproducts`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        getRecommendedCategories(context, slug) {
            return axios
                .get(`/api/v1/products/${slug}/recommendedcategories`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    alert(error);
                });
        },

        getBoughtWithProducts(context, slug) {
            return axios
                .get(`/api/v1/products/${slug}/boughtwithproducts`)
                .then(function (response) {
                    return response.data;
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },




};
